
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<v-layout row wrap class="mb-8" style="    align-items: center;
">
				<v-flex xs12 lg8 xl8 md8 sm4>

					<h1 class="mt-4 mb-5">{{$store.getters.language.data.employees.info}}</h1>
				</v-flex>
				<v-spacer></v-spacer>
				<v-flex xs12 lg2 xl2 md2 sm4>
					<v-btn color="primary" :to="'/file_attachments-list/'+employees.employee_id">File Attachments</v-btn>
				</v-flex>
			</v-layout>
			<v-layout row wrap class="mb-8">
				<v-flex xs12 lg2 xl2 md12 sm12 v-for="item,index in fileRows" :key="index">

					<div class="mx-3">
						<v-img max-width="500" v-bind:src="$imageURL + 'items/image/' + item.profile_attachment_path" />
					</div>

				</v-flex>
			</v-layout>
			<br>
			<!-- btn to go to /file_attachments-list/:id -->

			<form @submit.prevent="addProfileAttachments" autocomplete="off">
				<v-layout row wrap class="my-7">

					<v-flex xs12 lg4 xl4 md4 sm4>

						<v-btn :color="img_file == null ? 'secondary' : 'teal'" :outlined="img_file == null" :loading="loading_btn" large style="width:100%" @click="openFile">
							Choose
							<!-- <v-icon>fas fa-check</v-icon> -->
						</v-btn>
					</v-flex>
					<v-flex xs12 lg1 xl1 md3 sm4>
						<input type="file" @change="processFile" ref="file" style=" display:none" />
					</v-flex>
					<v-flex xs12 lg4 xl4 md4 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit" v-if="img_file != null" large style="width:100%">Upload</v-btn>
					</v-flex>
				</v-layout>

			</form>

			<form @submit.prevent="updateEmployees" autocomplete="off">
				<v-layout row wrap>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_name" type="text" :label="$store.getters.language.data.employees.employee_name" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_birth" type="date" :label="$store.getters.language.data.employees.employee_birth" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="positions" v-model="employees.position_id" dense filled outlined item-text="position_name" item-value="position_id" :return-object="false" :label="$store.getters.language.data.positions.position_name">
						</v-select>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_contract_start_date" type="date" :label="$store.getters.language.data.employees.employee_contract_start_date" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_contract_end_date" type="date" :label="$store.getters.language.data.employees.employee_contract_end_date" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="['construction_site','office','showroom','mixed']" v-model="employees.employee_work_place" dense filled outlined item-text="employee_work_place" item-value="employee_work_place" :return-object="false" :label="$store.getters.language.data.employees.employee_work_place">
						</v-select>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_phone_1" type="textarea" :label="$store.getters.language.data.employees.employee_phone_1" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_phone_2" type="textarea" :label="$store.getters.language.data.employees.employee_phone_2" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="working_hours" v-model="employees.working_hour_id" dense filled outlined item-text="hours" item-value="working_hour_id" :return-object="false" :label="$store.getters.language.data.working_hours.title">
						</v-select>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="['active','terminated']" v-model="employees.employee_status" dense filled outlined item-text="employee_status" item-value="employee_status" :return-object="false" :label="$store.getters.language.data.employees.employee_status">
						</v-select>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_off_days_year" type="number" :label="$store.getters.language.data.employees.employee_off_days_year" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_off_hours_year" type="number" :label="$store.getters.language.data.employees.employee_off_hours_year" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_base_salary" type="text" :label="$store.getters.language.data.employees.employee_base_salary" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_food_budget" type="text" :label="$store.getters.language.data.employees.employee_food_budget" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_transportation_budget" type="text" :label="$store.getters.language.data.employees.employee_transportation_budget" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_phone_budget" type="text" :label="$store.getters.language.data.employees.employee_phone_budget" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_driver_budget" type="text" :label="$store.getters.language.data.employees.employee_driver_budget" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_government_insurance" type="text" :label="$store.getters.language.data.employees.employee_government_insurance" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="employees.employee_insurance_rate" type="text" :label="$store.getters.language.data.employees.employee_insurance_rate" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="users" v-model="employees.user_id" dense filled outlined item-text="user_name" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_name" disabled>
						</v-select>
					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="positions" v-model="employees.supervisor_id" dense filled outlined item-text="position_name" item-value="position_id" :return-object="false" :label="$store.getters.language.data.employees.supervisor">
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="success" type="submit">{{$store.getters.language.data.employees.update_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>

		</v-container>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/employees.request.js'
	import position_requests from './../../requests/positions.request.js'
	import working_hoursRequests from './../../requests/working_hours.request.js'
	import filerequests from './../../requests/profile_attachments.request.js'

	export default {
		data() {
			return {
				employees: {},
				positions: [],
				fileRows: [],
				working_hours: [],
				file_attachments: {},
				img_file: null,
				id: '',
				loading: true,
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
			}
		},
		computed: {

			// positions() {
			// 	return this.$store.getters.positions_list
			// },

			// working_hours() {
			// 	return this.$store.getters.working_hours_list
			// },

			users() {
				return this.$store.getters.users_list
			},
            user() {
                return this.$store.getters.user
            },

		},
		mounted() {
			this.id = this.$route.params.id
            
			this.readPositions();

			this.getOneEmployees();
			this.readFileAttachments(1)
		},
		methods: {
			openFile() {
				this.$refs.file.click();
			},
			processFile($event) {
				this.img_file = $event.target.files[0];
			},
			addProfileAttachments() {
				this.loading_btn = true
				var formData = new FormData();
				formData.append('employee_id', this.id);
				formData.append('user_id', this.user.user_id);
				formData.append('profile_attachment_path', this.img_file);

				filerequests.createProfileAttachments(formData).then(r => {
					if (r.status == 200) {
						this.readFileAttachments(this.file_attachments)
						this.prifile_attachments = {}
						this.prifile_attachments.user_id = this.user.user_id
						this.prifile_attachments.employee_id = this.id
						this.snackbar = {
							value: true,
							text: 'ProfileAttachments Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add ProfileAttachments',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			readFileAttachments(i) {
				this.loading = true
				var item_id = i.shop_item_id
				filerequests.getProfileAttachmentsByColumn('employee_id', this.id).then(r => {
					if (r.status == 200) {
						this.fileRows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read FileAttachments',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read FileAttachments',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			readWorkingHours() {
				this.loading = true
				working_hoursRequests.getAllWorkingHours().then(r => {
					if (r.status == 200) {
						this.working_hours = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read WorkingHours',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read WorkingHours',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			readPositions() {
				this.loading = true
				position_requests.getAllPositions().then(r => {
					if (r.status == 200) {
						this.positions = r.data.rows
						this.readWorkingHours()
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Positions',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Positions',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			getOneEmployees() {
				this.loading = true
				requests.getOneEmployees(this.id).then(r => {
					if (r.status == 200) {
						this.employees = r.data.row
						this.loading = false
						this.employees.employee_birth = this.employees.employee_birth.split('T')[0]
						this.employees.employee_contract_start_date = this.employees.employee_contract_start_date.split('T')[0]
						this.employees.employee_contract_end_date = this.employees.employee_contract_end_date.split('T')[0]
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employees',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			updateEmployees() {
				this.loading = true
                this.employees.user_id = this.user.user_id
				requests.updateEmployees(this.id, this.employees).then(r => {
					if (r.status == 200) {
						this.snackbar = {
							value: true,
							text: 'Employees Updated',
							color: 'success'
						}
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					})
					.finally(() => {
						this.loading = false
					})
			},
		},
	}
</script>
                    
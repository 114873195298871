
import axios from 'axios';
export default {
    
    async createPositions(params)  {
        return await axios.post(`positions/create` , params)
    },
    async createPositionsList(params)  {
        return await axios.post(`positions/create/list` , params)
    },
    async updatePositionsColumn(column , value , data)  {
        return await axios.put(`positions/update_list?${column}=${value}` , data)
    },
    async deletePositionsList(list)  {
        return await axios.delete(`positions/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportPositions(column , value)  {
        return await axios.get(`positions/report?${column}=${value}`)
    },
    async getAllPositions()  {
        return await axios.get(`positions/all`)
    },
    async getOnePositions(position_id)  {
        return await axios.get(`positions/all/${position_id}`)
    },
    async getPositionsByColumn(column , value)  {
        return await axios.get(`positions/filter?column=${column}&value=${value}`)
    },
    async deletePositions(position_id)  {
        return await axios.delete(`positions/delete/${position_id}`)
    },
    async updatePositions(position_id , params)  {
        return await axios.put(`positions/update/${position_id}` , params)
    }
}